* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4e3c9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: "Impact", sans-serif;
  color: black;
  font-size: 2.2rem;
  text-align: center;
  font-weight: lighter;
}

h3 {
  font-family: HelveticaBtn;
  color: black;
  font-size: 0.75rem;
  text-align: center;
  font-weight: bold;
  margin: auto;
  padding: 0.5rem;
}

p {
  font-family: Helvetica;
  text-align: center;
  font-size: 0.9rem;
}

@font-face {
  font-family: "Impact";
  src: url("/public/font/impact/impact.ttf");
}

@font-face {
  font-family: "Helvetica";
  src: url("/public/font/helvetica/HelveticaNeueLTPro-Roman.otf");
}

@font-face {
  font-family: "HelveticaBtn";
  src: url("/public/font/helvetica/HelveticaNeueLTPro-Ex.otf");
}

.imgContainer {
  margin: 0 auto;
  width: fit-content;
  min-height: fit-content;
}

.imgContainer img {
  height: 100%;
}

.smallerthenP{
  font-size: 0.8rem;
}

.score {
  font-family: "Impact", sans-serif;
  width: 100px;
  height: 100px;
  line-height: 90px;
  border-radius: 50%;
  border-color: #f2a900;
  border-width: 3px;
  border-style: solid;
  font-size: 25px;
  color: #f4e3c9;
  text-align: center;
  background: #000;
  /* transform: translate(170%, -60%) rotate(10deg); */
  text-align: center;
  position: relative;
  z-index: 8;
}

.table {
  font-family: Helvetica;
  width: 90%;
  border-collapse: collapse;
  background-color: #f4e3c9;
  border: 2px solid #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.table th,
.table td {
  font-family: Helvetica;
  padding: 0.5rem;
  font-family: Arial, sans-serif;
  color: #000;
  max-width: 8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.table th {
  font-family: Helvetica;
  border-bottom: 2px solid #000;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
}

.table th:last-child {
  font-family: Helvetica;
  text-align: center;
}

.table td {
  font-weight: bold; /* double check font */
  font-family: Helvetica;
  border-bottom: 1px solid #000;
  font-size: 0.8rem;
  padding: 1.3svh 0;
}

.table td:last-child {
  font-family: Helvetica;
  text-align: center;
}

/* PageTransition.css */
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 100ms;
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.floating {
  animation: floating 3s ease-in-out infinite;
}

input:focus-visible {
  outline: none;
}

#input::placeholder {
  color: #9A9A9A;
}

@media (min-height: 700px) {
  .specialBlock {
    height: 3svh;
  }
}

